import { Link } from "gatsby"
import React from "react"
import gift from "../../assets/images/d-1.png"
import { StaticQuery, graphql } from "gatsby"

const Step1 = () => (
  <StaticQuery
  query={graphql`
    query {
      allWpPage(filter: {slug: {eq: "calculator"}}) {
        edges {
          node {
            TeamlanceCalculator {
              calculatorSteps {
                emailPlaceholder
                fieldGroupName
                namePlaceholder
                phonePlaceholder
                privacyText
                steps {
                  buttonText
                  description
                  fieldGroupName
                  heading
                  iconSvg
                  icon {
                    sourceUrl
                  }
                  options {
                    optionTitle
                  }
                  percentComplete
                  title
                }
              }
              designSettings {
                buttonBg
                leftMenuBg
                leftTextColor
                logo {
                  sourceUrl
                }
                logoSvg
                rightTextColor
              }
            }
            slug
          }
        }
      }
    }
  `}
  render={data => {
      return(
        data && data.allWpPage &&
        data.allWpPage.edges &&
        data.allWpPage.edges[0] &&
        data.allWpPage.edges[0].node &&
        data.allWpPage.edges[0].node.TeamlanceCalculator &&
        data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps &&
        data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps&&
        data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps.length>0&&
        <div className="mainContentWrapper">
        <div className="dashContentHeader"></div>
         <div className="row dashboardCardRow">
          <div className="col-md-10 col-lg-8 d-flex align-items-center ">
            <div className="dashboard-content">
              <figure className="my-5">
                <img src={gift} alt="info" />
              </figure>
              <h1>{data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[0].heading}</h1>
              <p className="mb-5">
                 {data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[0].description}
              </p>
              <form>
                <div className="form-check-inline radio-form">
                  <label className="customradio mb-0">
                    <span className="radiotextsty">{data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[0].options[0].optionTitle}</span>
                    <input type="radio" checked="checked" name="radio" />
                    <span className="checkmark"></span>
                  </label>{" "}
                         
                </div>
                <div className="form-check-inline radio-form">
                  <label className="customradio">
                    <span className="radiotextsty">{data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[0].options[1].optionTitle}</span>
                    <input type="radio" name="radio" />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </form>
              <div className="text-left">
                <Link to='/calculator/calculator-step-2'>  
                  <button
                    data-abc="true"
                    className="btn btn-default waves-effect waves-light next-step"
                  >
                    {data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[0].buttonText}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
       </div>
      )
    }}
  /> 
)

export default Step1
